import React from 'react';
import { useAccount, useSigner } from 'wagmi';
import styles from './BindSocialPlatforms.module.css';
import twitterLogo from '@/assets/svg/twitter-logo.svg';
import discordLogo from '@/assets/svg/discord-logo.svg';
import { useSelector } from '@/state/hook';
import DoneSvg from '@/assets/svg/done-darker.svg';
import { TWITTER_AUTH_URL, DISCORD_AUTH_URL, CHAIN } from '@/config';
import store from '@/utils/storage';
import { SiweMessage } from 'siwe';

function getWindowFeatures({
  width,
  height,
  top,
  left,
}: {
  width: number;
  height: number;
  top: number;
  left: number;
}) {
  return `width=${width}, height=${height}, top=${top}, left=${left}, popup`;
}

let left = 0;
let top = 0;
const width = 600;
const height = innerHeight;
top = Math.max(screenY, (outerHeight - height) / 2 + screenY + 25);
left = Math.max(screenX + (outerWidth - width) / 2, 0);

const windowFeatures = getWindowFeatures({ width, height, top, left });

export const BindSocialPlatform: React.FC = () => {
  const { data: signer } = useSigner();

  const domain = window.location.host;
  const { origin } = window.location;

  const createSiweMessage = (address: string, statement: string) => {
    const now = new Date();
    const oneYearLater = new Date(now.getTime() + 24 * 60 * 60 * 365 * 1000);
    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: '1',
      chainId: CHAIN.idDecimal,
      expirationTime: oneYearLater.toISOString(),
    });
    return message.prepareMessage();
  };

  const signInWithEthereum = async () => {
    const statement = `Sign in with Ethereum to the app.`;
    const message = createSiweMessage(await signer!.getAddress(), statement);
    const sig = await signer!.signMessage(message);
    const siweBody = {
      Address: await signer!.getAddress(),
      Sig: sig,
      Msg: message,
    };
    console.log({ siweBody });
    store.setItem(`siwe:${address}`, siweBody);
  };

  const twitterAuthorise = () => {
    open(TWITTER_AUTH_URL, 'twitterTab', windowFeatures);
  };

  const discordAuthorise = () => {
    open(DISCORD_AUTH_URL, 'discordTab', windowFeatures);
  };

  const { address } = useAccount();
  const isTwitterBinded = useSelector(
    state =>
      state.bindSocialMedia.walletAddressSocialMediaBindingMap[address!]
        ?.twitter,
  );
  const isDiscordBinded = useSelector(
    state =>
      state.bindSocialMedia.walletAddressSocialMediaBindingMap[address!]
        ?.discord,
  );
  console.log({ isTwitterBinded }, { isDiscordBinded });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        You need to authorise your twitter & discord to continue.
      </div>
      <div className={styles.body}>
        <div
          className={styles.block}
          onClick={async () => {
            const siwe = store.getItem(`siwe:${address}`);
            if (!siwe) {
              await signInWithEthereum();
            }
            twitterAuthorise();
          }}
        >
          <img className={styles.logo} src={twitterLogo} />
          <div className={styles.button}>
            {isTwitterBinded ? (
              <img style={{ width: '25px', color: 'black' }} src={DoneSvg} />
            ) : (
              <div className={styles.text}>Twitter</div>
            )}
          </div>
        </div>
        <div
          className={styles.block}
          onClick={async () => {
            const siwe = store.getItem(`siwe:${address}`);
            if (!siwe) {
              await signInWithEthereum();
            }
            discordAuthorise();
          }}
        >
          <img className={styles.logo} src={discordLogo} />
          <div className={styles.button}>
            {isDiscordBinded ? (
              <img style={{ width: '25px', color: 'black' }} src={DoneSvg} />
            ) : (
              <div className={styles.text}>Discord</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
