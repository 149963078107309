/* eslint-disable max-lines */
import React from 'react';
import { Steps } from '@arco-design/web-react';
import { useAccount, useSigner } from 'wagmi';
import { Follow } from 'react-twitter-widgets';
import { SiweMessage } from 'siwe';
import { useHistory } from '@modern-js/runtime/router';
import styles from './Whitelist.module.css';
import task1 from './tasks/1.json';
import task2 from './tasks/2.json';
import task3 from './tasks/3.json';
import task4 from './tasks/4.json';
import task5 from './tasks/5.json';
import { BindSocialPlatform } from './components/BindSocialPlatforms';
import { InputTweetUrl } from './components/InputTweetUrl';
import { ConnectWalletWarning } from './components/ConnetWalletWarning';
import { AlreadyHaveWhitelist } from './components/AlreadyHaveWhitelist';
import LoadingSvg from '@/assets/svg/loading.svg';
import Message, { MessageTypes } from '@/components/Message';
import DoneSvg from '@/assets/svg/done.svg';
import UndoneSvg from '@/assets/svg/radio-button.svg';
import Header from '@/components/Header';
import Modal from '@/components/Modal';
import {
  acquireWhitelist,
  fetchTaskProcess,
  requestNextTask,
} from '@/state/taskProcess/slice';
import { useSelector, useAppDispatch } from '@/state/hook';
import '@arco-design/web-react/dist/css/arco.css';
import {
  verifyCollaborators,
  verifyCreateCuration,
  verifyCreateTaskFolder,
  verifyCurateOnOpensea,
  verifyExtensionInstalled,
  verifyFollowTwitter,
  verifyJoinCollaboration,
  verifyJoinDiscord,
  verifyNicknameAndAvatar,
  verifyTwitterBio,
  verifyUnlockCurations,
  verifyUnlockedTimes,
  verifyShareFolderToTwitter,
} from '@/utils/verifyTask';
import store from '@/utils/storage';
import { CHAIN, EXTENSION_ID } from '@/config';
import BigDoneSvg from '@/assets/svg/big-done.svg';
import {
  fetchWhetherUserHasWhitelist,
  othersSlice,
} from '@/state/others/slice';

const taskMap = new Map([
  [1, task1],
  [2, task2],
  [3, task3],
  [4, task4],
  [5, task5],
]);

export default React.memo(() => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loadingArray, updateLoadingArray] = React.useState<Array<boolean>>([
    false,
    false,
    false,
    false,
  ]);
  const { address, isConnected, isDisconnected } = useAccount();
  const [isExtensionInstalled, setIsExtensionInstalled] =
    React.useState<boolean>(false);
  const { Step } = Steps;
  const isTwitterBinded = useSelector(
    state =>
      state.bindSocialMedia.walletAddressSocialMediaBindingMap[address!]
        ?.twitter,
  );
  const isDiscordBinded = useSelector(
    state =>
      state.bindSocialMedia.walletAddressSocialMediaBindingMap[address!]
        ?.discord,
  );
  console.log({ isTwitterBinded }, { isDiscordBinded });

  const [modalVisible, setModalVisible] = React.useState<boolean>(
    !(isTwitterBinded && isDiscordBinded),
  );

  React.useEffect(() => {
    setModalVisible(!(isTwitterBinded && isDiscordBinded));
  }, [isTwitterBinded, isDiscordBinded]);

  const { data: signer } = useSigner();

  const domain = window.location.host;
  const { origin } = window.location;

  const createSiweMessage = (address: string, statement: string) => {
    const now = new Date();
    const oneYearLater = new Date(now.getTime() + 24 * 60 * 60 * 365 * 1000);
    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: '1',
      chainId: CHAIN.idDecimal,
      expirationTime: oneYearLater.toISOString(),
    });
    return message.prepareMessage();
  };

  const signInWithEthereum = async () => {
    console.log('sign in with ethereum...');
    const statement = `Sign in with Ethereum to the app.`;
    const message = createSiweMessage(await signer!.getAddress(), statement);
    const sig = await signer!.signMessage(message);
    const siweBody = {
      Address: await signer!.getAddress(),
      Sig: sig,
      Msg: message,
    };
    console.log({ siweBody });
    store.setItem(`siwe:${address}`, siweBody);
  };

  React.useEffect(() => {
    (async () => {
      console.log({ address });
      if (!address) {
        return;
      }
      const siwe = store.getItem(`siwe:${address}`);
      console.log({ siwe });
      if (!siwe) {
        console.log(1111111);
        await signInWithEthereum();
      }
    })();
  }, []);

  React.useEffect(() => {
    if (isDiscordBinded && isTwitterBinded) {
      setTimeout(() => {
        setModalVisible(false);
      }, 3000);
    }
  }, [isDiscordBinded, isTwitterBinded]);
  const [inputModalVisible, setInputModalVisible] =
    React.useState<boolean>(false);
  const _currentTask = useSelector(state => state.taskProcess.task);
  const _completedSteps = useSelector(state => state.taskProcess.steps);
  const hasWhitelist = useSelector(state => state.others.hasWhitelist);

  const haveFinishedAllTasks = useSelector(
    state => state.taskProcess.haveFinishedAllTasks,
  );

  const tweetUrl = useSelector(state => state.others.tweetUrl);
  const siwe = store.getItem(`siwe:${address}`);

  const [warningModalVisible, setWarningModalVisible] = React.useState<boolean>(
    !isConnected,
  );

  React.useEffect(() => {
    console.log({ isConnected }, { isDisconnected });
    if (!isConnected) {
      setWarningModalVisible(true);
    }
  }, [isConnected, isDisconnected]);

  React.useEffect(() => {
    console.log({ warningModalVisible });
  }, [warningModalVisible]);

  const handleVerify = async (task: number, step: number) => {
    console.log({ task }, { step });
    switch (task) {
      case 1:
        switch (step) {
          case 1:
            const _res = await verifyExtensionInstalled(EXTENSION_ID);
            setIsExtensionInstalled(_res);
            return;
          case 2:
            return await verifyFollowTwitter(siwe, '@DataverseOS');
          case 3:
            return await verifyFollowTwitter(siwe, '@OwnershipLabs');
          case 4:
            return await verifyJoinDiscord(siwe);
          default:
            break;
        }
        break;

      case 2:
        switch (step) {
          case 1:
            return await verifyNicknameAndAvatar(siwe);
          case 2:
            return await verifyTwitterBio(siwe);
          default:
            break;
        }
        break;

      case 3:
        switch (step) {
          case 1:
            return await verifyCreateTaskFolder(siwe);
          case 2:
            return await verifyCurateOnOpensea(siwe);
          case 3:
            setInputModalVisible(true);
            return true;
          default:
            break;
        }
        break;

      case 4:
        switch (step) {
          case 1:
            return await verifyCreateCuration(siwe);
          case 2:
            setInputModalVisible(true);
            return true;
          case 3:
            return await verifyCollaborators(siwe);
          case 4:
            return await verifyUnlockedTimes(siwe);
          default:
            break;
        }
        break;

      case 5:
        switch (step) {
          case 1:
            return await verifyUnlockCurations(siwe);
          case 2:
            return await verifyJoinCollaboration(siwe);
          default:
            break;
        }
        break;

      default:
        break;
    }
    return false;
  };

  React.useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(fetchTaskProcess(address));
  }, [address]);

  React.useEffect(() => {
    (async () => {
      const _res = await verifyExtensionInstalled(EXTENSION_ID);
      setIsExtensionInstalled(_res);
    })();
  }, []);

  React.useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(fetchWhetherUserHasWhitelist(address));
  }, [address]);

  React.useEffect(() => {
    (async () => {
      if (tweetUrl) {
        switch (_currentTask) {
          case 3:
            const res1 = await verifyShareFolderToTwitter(
              siwe,
              'DataverseOS',
              tweetUrl,
            );
            console.log({ res1 });
            if (res1 === false) {
              Message({
                content: 'Failed to verify!',
                type: MessageTypes.Error,
              });
            } else {
              dispatch(fetchTaskProcess(address!));
            }
            break;
          case 4:
            const res2 = await verifyShareFolderToTwitter(
              siwe,
              'CurationDAO',
              tweetUrl,
            );
            console.log({ res2 });
            if (res2 === false) {
              Message({
                content: 'Failed to verify!',
                type: MessageTypes.Error,
              });
            } else {
              dispatch(fetchTaskProcess(address!));
            }
            break;
          default:
            break;
        }
        setInputModalVisible(false);
        dispatch(othersSlice.actions.setTweetUrl(''));
      }
    })();
  }, [tweetUrl]);

  // console.log({ _currentTask }, { _completedSteps });

  const Callout = (
    <div className={styles.callout}>
      You will need a Lens Profile to continue.
      <a
        target="_blank"
        style={{ color: 'white', marginLeft: '5px' }}
        href="https://opensea.io/collection/lens-protocol-profiles?search[sortAscending]=true&search[sortBy]=UNIT_PRICE"
      >
        Get one on OpenSea
      </a>
    </div>
  );

  const HaveFinishedTasks = (
    <div className={styles['have-finished-tasks']}>
      <img className={styles['have-finished-tasks__icon']} src={BigDoneSvg} />
      <div className={styles['have-finished-tasks__text']}>
        Congratulations! You have already finished all the tasks and acquired
        the whitelist. Go to mint your Prisma!
      </div>
      <div className={styles['have-finished-tasks__button']}>
        <div
          className={styles['have-finished-tasks__button__text']}
          onClick={() => {
            history.push('/');
          }}
        >
          Go to Mint
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.main}>
      <Modal
        controlVisible={modalVisible}
        // controlVisible={false}
        showCancelButton={false}
        showOkButton={false}
        mask={true}
        onCancel={() => {
          console.log('modal close');
        }}
        canClickOutsideToClose={false}
      >
        <BindSocialPlatform />
      </Modal>
      <Modal
        controlVisible={inputModalVisible}
        showCancelButton={false}
        showOkButton={false}
        mask={true}
        onCancel={() => {
          console.log('input modal close');
        }}
        onClose={() => {
          setInputModalVisible(false);
        }}
      >
        <InputTweetUrl />
      </Modal>
      {/* <Modal
        controlVisible={warningModalVisible}
        showCancelButton={false}
        showOkButton={false}
        mask={true}
        onCancel={() => {
          console.log('input modal close');
        }}
      >
        <ConnectWalletWarning />
      </Modal> */}
      <Modal
        controlVisible={hasWhitelist}
        // controlVisible={true}
        showCancelButton={false}
        showOkButton={false}
        mask={true}
        onCancel={() => {
          console.log('modal close');
        }}
        canClickOutsideToClose={false}
      >
        <AlreadyHaveWhitelist />
      </Modal>
      <Header />
      <div className={styles.content}>
        <div className={styles['task-container']}>
          <div className={styles['progress-area']}>
            <div className={styles['progress-title']}>Your Progress</div>
            <div className={styles.progress}>
              <Steps
                type="dot"
                current={_currentTask}
                style={{ maxWidth: 780, marginBottom: 40 }}
              >
                <Step title="Task1" description="🪐 Enter Dataverse" />
                <Step
                  title="Task2"
                  description="🦊 Build your digital identity"
                />
                <Step title="Task3" description="💐 Let's curate!" />
                <Step title="Task4" description="🏺 Host a curation DAO" />
                {/* <Step
                  title="Task5"
                  description="🌐 Curation connects each other"
                /> */}
              </Steps>
            </div>
          </div>
          {haveFinishedAllTasks ? (
            HaveFinishedTasks
          ) : (
            <div className={styles['task-area']}>
              <div className={styles['task-area__title']}>
                Task {_currentTask}
              </div>
              {_currentTask === 4 ? Callout : <div></div>}
              <div className={styles['task-area__body']}>
                {taskMap.get(_currentTask)!.steps.map((step, index) => {
                  return (
                    <div
                      className={styles['task-area__body__step-card']}
                      key={`step${index}`}
                    >
                      <div
                        className={
                          styles['task-area__body__step-card__container']
                        }
                      >
                        <div
                          className={
                            styles[
                              'task-area__body__step-card__container__icon'
                            ]
                          }
                        >
                          {_currentTask === 1 && step.number === 1 ? (
                            isExtensionInstalled ? (
                              <img src={DoneSvg} />
                            ) : (
                              <img src={UndoneSvg} />
                            )
                          ) : _completedSteps.includes(step.number) ? (
                            <img src={DoneSvg} />
                          ) : (
                            <img src={UndoneSvg} />
                          )}
                        </div>
                        <div
                          className={styles['task-area__body__step-card__text']}
                        >
                          {_currentTask === 4 && step.number === 1 ? (
                            <div>
                              <div style={{ marginTop: '8px' }}>
                                {step.content}
                              </div>
                              <div
                                className={
                                  styles['task-area__body__step-card__tips']
                                }
                              >
                                To create a curation folder, create a public
                                folder first, choose it then click the ·Publish·
                                button.
                              </div>
                            </div>
                          ) : (
                            <>{step.content}</>
                          )}
                        </div>
                      </div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                          className={
                            styles['task-area__body__step-card__button']
                          }
                        >
                          <a
                            href={
                              _currentTask === 2 && step.number === 2
                                ? `${step.targetUrl}${address}`
                                : step.targetUrl
                            }
                            target="_blank"
                            style={{ textDecoration: 'none', color: 'white' }}
                          >
                            <div
                              className={
                                styles['task-area__body__step-card__text']
                              }
                            >
                              Go to
                            </div>
                          </a>
                        </div>
                        <div
                          className={
                            styles['task-area__body__step-card__button']
                          }
                        >
                          <div
                            className={
                              styles['task-area__body__step-card__text']
                            }
                            onClick={async () => {
                              updateLoadingArray(prevState => {
                                const newArray = prevState.map(
                                  (value, index) => {
                                    if (index === step.number - 1) {
                                      return true;
                                    }
                                    return value;
                                  },
                                );
                                return newArray;
                              });
                              const res = await handleVerify(
                                _currentTask,
                                step.number,
                              );
                              if (res === false) {
                                Message({
                                  content: 'Failed to verify!',
                                  type: MessageTypes.Error,
                                });
                              }
                              updateLoadingArray(prevState => {
                                const newArray = prevState.map(
                                  (value, index) => {
                                    if (index === step.number - 1) {
                                      return false;
                                    }
                                    return value;
                                  },
                                );
                                return newArray;
                              });
                              dispatch(fetchTaskProcess(address!));
                            }}
                          >
                            {loadingArray[step.number - 1] ? (
                              <img
                                style={{
                                  height: '26px',
                                  width: '26px',
                                  marginTop: '3px',
                                }}
                                src={LoadingSvg}
                              />
                            ) : (
                              'Verify'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {_currentTask === 4 ? (
                <></>
              ) : taskMap.get(_currentTask)!.steps.length >
                _completedSteps.length ? (
                <div className={styles['task-area__button-disabled']}>
                  <div className={styles['task-area__button-container__text']}>
                    Next
                  </div>
                </div>
              ) : (
                <div className={styles['task-area__button-container']}>
                  <div
                    className={styles['task-area__button-container__text']}
                    onClick={() => {
                      const siwe = store.getItem(`siwe:${address}`);
                      dispatch(requestNextTask(JSON.parse(siwe)));
                    }}
                  >
                    Next
                  </div>
                </div>
              )}
              {/* {taskMap.get(_currentTask)!.steps.length >
              _completedSteps.length ? (
                <div className={styles['task-area__button-disabled']}>
                  <div className={styles['task-area__button-container__text']}>
                    {_currentTask === 4 ? 'Finish' : 'Next'}
                  </div>
                </div>
              ) : (
                <div className={styles['task-area__button-container']}>
                  <div
                    className={styles['task-area__button-container__text']}
                    onClick={() => {
                      const siwe = store.getItem(`siwe:${address}`);
                      if (_currentTask < 4) {
                        dispatch(requestNextTask(JSON.parse(siwe)));
                      } else {
                        dispatch(acquireWhitelist(JSON.parse(siwe)));
                      }
                    }}
                  >
                    {_currentTask === 4 ? 'Finish' : 'Next'}
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
