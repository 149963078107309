import React from 'react';
import styles from './AlreadyHaveWhitelist.module.css';
import BigDoneSvg from '@/assets/svg/big-done.svg';

export const AlreadyHaveWhitelist: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={BigDoneSvg} />
      <div className={styles.title}>
        You already have a whitelist. There is no need to do these tasks.
      </div>
    </div>
  );
};
