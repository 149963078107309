import styled from 'styled-components';

export const ButtonWrap = styled.div<{
  width: number | string;
  type: string;
}>`
  .buttonContainer {
    // default
    cursor: pointer;
    appearance: auto;
    user-select: none;
    white-space: pre;
    align-items: flex-start;
    text-align: center;
    box-sizing: border-box;
    background-color: buttonface;
    color: buttontext;
    border: none;
    border-radius: 6px;
    white-space: nowrap;
    height: 32px;
    padding: 4px 15px;
    width: fit-content;
    min-width: ${props =>
      typeof props.width === 'number' ? `${props.width}px` : props.width};

    // primary
    ${props =>
      props.type === 'primary'
        ? 'color: rgb(255, 255, 255); background-color:rgb(0, 0, 0);'
        : ''}

    // text
    ${props =>
      props.type === 'text'
        ? 'color: rgb(0, 0, 0); background-color:rgba(0, 0, 0, 0);'
        : ''}

    transition: box-shadow 0.1s;
  }
  .buttonContainer:hover {
    ${props =>
      props.type !== 'text'
        ? `box-shadow: 0px 0px 3px #333333;
          -webkit-box-shadow: 0px 0px 3px #333333;
          box-shadow: 0px 0px 3px #333333;`
        : ''}
  }
`;
