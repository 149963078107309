import React from 'react';
import styles from './Intro.module.css';
import introTitle from '@/assets/images/intro-title.png';

export default React.memo(() => {
  return (
    <div className={styles['intro-container']}>
      {/* <div className={styles.title}>PRISMA x DATAVERSE</div> */}
      <img src={introTitle} className={styles.title} />
      <div className={styles.content}>
        Prisma NFT is a collection of 10,000 NFTs derived from the Dataverse OS
        protocol which can be used to unlock platform features and benefits.
      </div>
      <div className={styles.content}>
        Inspired by the story of Prometheus stealing fire and restoring it to
        humanity in Greek mythology, Prisma is the fire spirit of the data world
        that brings true digital ownership back to users.
      </div>
      <div className={styles.rect}></div>
    </div>
  );
});
