import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button';
import { ModalWrap } from './ModalWrap';
import usePortal from '@/utils/usePortal';
import { useClickOutside } from '@/utils/useClickOutSide';

export interface ModalProps {
  children?: ReactNode;
  onCancel: () => void;
  onOk?: () => void;
  trigger?: JSX.Element;
  id?: string;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  showOkButton?: boolean;
  canClickOutsideToClose?: boolean;
  controlVisible?: boolean;
  hiddenScroll?: boolean;
  portal?: boolean;
  parentId?: string;
  mask?: boolean;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onCancel,
  onOk,
  id = uuidv4(),
  controlVisible = false,
  showCloseButton = false,
  showCancelButton = true,
  showOkButton = true,
  canClickOutsideToClose = true,
  hiddenScroll = true,
  portal = false,
  parentId = 'root',
  trigger,
  mask = false,
  onClose,
}) => {
  const [visible, setVisible] = useState(controlVisible);
  const modalRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setVisible(controlVisible);
  }, [controlVisible]);

  React.useEffect(() => {
    if (!visible) {
      onClose?.();
    }
  }, [visible]);

  const triggerDom = useMemo(() => {
    if (!trigger) {
      return null;
    }

    return React.cloneElement(trigger, {
      key: 'trigger',
      ...trigger.props,
      onClick: async (e: any) => {
        setVisible(!visible);
        trigger.props?.onClick?.(e);
      },
    });
  }, [setVisible, trigger, visible]);

  const handleClose = () => {
    setVisible(false);
  };

  useClickOutside(modalRef, () => {
    if (canClickOutsideToClose) {
      handleClose();
    }
  });

  useEffect(() => {
    if (visible && hiddenScroll) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0px';
    }
  }, [visible, id]);

  const target = usePortal(parentId);
  const elements = (
    <ModalWrap visible={visible} mask={mask}>
      <div id={id} className="maskContainer">
        <div className="modalContainer" ref={modalRef}>
          <div className="headerContainer">
            {showCloseButton && (
              <Button
                onClick={() => {
                  handleClose();
                }}
                width={20}
                type={'text'}
                label={'X'}
              />
            )}
          </div>
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className="childrenContainer"
          >
            {children}
          </div>

          <div className="footerContainer">
            {showCancelButton ? (
              <Button
                label="cancel"
                onClick={() => {
                  handleClose();
                  onCancel?.();
                }}
                style={{ marginRight: 10 }}
              />
            ) : (
              <></>
            )}
            {showOkButton ? (
              <Button
                label="ok"
                type="primary"
                onClick={() => {
                  handleClose();
                  onOk?.();
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </ModalWrap>
  );
  return portal ? (
    <>
      {triggerDom}
      {createPortal(elements, target)}
    </>
  ) : (
    <>
      {triggerDom}
      {elements}
    </>
  );
};

export default Modal;
