/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { toString as uint8ArrayToString } from 'uint8arrays/to-string';
import { fromString as uint8ArrayfromString } from 'uint8arrays/from-string';
import {
  useAccount,
  useContract,
  useContractWrite,
  usePrepareContractWrite,
  useSigner,
  useBalance,
  useNetwork,
  useContractRead,
} from 'wagmi';
import { SendTransactionResult } from '@wagmi/core';
import { SiweMessage } from 'siwe';
import { ethers, Wallet } from 'ethers';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import axios from 'axios';
import classNames from 'classnames';
import { Follow } from 'react-twitter-widgets';
import { useHistory } from '@modern-js/runtime/router';
import { r } from '@wagmi/core/dist/index-bacc1c49';
import styles from './Home.module.css';
import Intro from './components/Intro';
import Interests from './components/Interests';
import Timeline from './components/Timeline';
import TryDataverse from './components/TryDataverse';
import { LoopGallery, Direction } from './components/LoopGallery';
import Index2 from './index2';
import {
  fetchWhetherUserHasWhitelist,
  fetchStage,
  StageProps,
} from '@/state/others/slice';
import { useSelector, useAppDispatch } from '@/state/hook';
import { getSig } from '@/utils/sig';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PrismaLogo from '@/assets/images/prisma-logo.jpg';
import Prisma1 from '@/assets/prismas/1.png';
import Prisma2 from '@/assets/prismas/2.png';
import Prisma3 from '@/assets/prismas/3.png';
import Prisma4 from '@/assets/prismas/4.png';
import Prisma5 from '@/assets/prismas/5.png';
import Prisma6 from '@/assets/prismas/6.png';
import Prisma7 from '@/assets/prismas/7.png';
import Prisma8 from '@/assets/prismas/8.png';
import Prisma9 from '@/assets/prismas/9.png';
import Prisma10 from '@/assets/prismas/10.png';
import Prisma11 from '@/assets/prismas/11.png';
import Prisma12 from '@/assets/prismas/12.png';
import Prisma13 from '@/assets/prismas/13.png';
import Prisma14 from '@/assets/prismas/14.png';
import Prisma15 from '@/assets/prismas/15.png';
import Prisma16 from '@/assets/prismas/16.png';
import Prisma17 from '@/assets/prismas/17.png';
import Prisma18 from '@/assets/prismas/18.png';
import Prisma19 from '@/assets/prismas/19.png';
import Prisma20 from '@/assets/prismas/20.png';
import Prisma21 from '@/assets/prismas/21.png';
import Prisma22 from '@/assets/prismas/22.png';
import Prisma23 from '@/assets/prismas/23.png';
import Prisma24 from '@/assets/prismas/24.png';
import Prisma25 from '@/assets/prismas/25.png';
import Prisma26 from '@/assets/prismas/26.png';
import Prisma27 from '@/assets/prismas/27.png';
import Prisma28 from '@/assets/prismas/28.png';
import Prisma29 from '@/assets/prismas/29.png';
import Prisma30 from '@/assets/prismas/30.png';
import Prisma31 from '@/assets/prismas/31.png';
import Prisma32 from '@/assets/prismas/32.png';
import Prisma33 from '@/assets/prismas/33.png';
import Prisma34 from '@/assets/prismas/34.png';
import Prisma35 from '@/assets/prismas/35.png';
import Prisma36 from '@/assets/prismas/36.png';
import Prisma37 from '@/assets/prismas/37.png';
import Prisma38 from '@/assets/prismas/38.png';
import Prisma39 from '@/assets/prismas/39.png';
import Prisma40 from '@/assets/prismas/40.png';
import loading from '@/assets/svg/loading.svg';
import loadingGray from '@/assets/svg/loading_gray.svg';
import loadingBlack from '@/assets/svg/loading_black.svg';

import {
  CHAIN,
  IS_MAINNET,
  PRISMA_BACKEND_URL,
  PRISMA_CONTRACT_ADDRESS,
  PRIVATE_KEY,
} from '@/config';
import prismaABI from '@/abis/Prisma.json';
import { Prisma } from '@/utils/Prisma';
import { BlurDivider } from '@/components/Divider';
import store from '@/utils/storage';
import Message, { MessageTypes } from '@/components/Message';
import background1 from '@/assets/images/background1.png';
import atmosphere1 from '@/assets/images/atmosphere1.png';
import totalSupply from '@/assets/images/total-supply.png';
import dataverseFont from '@/assets/images/DATAVERSE.png';

const arr1 = [
  Prisma1,
  Prisma2,
  Prisma3,
  Prisma4,
  Prisma5,
  Prisma6,
  Prisma7,
  Prisma8,
  Prisma9,
  Prisma10,
  Prisma11,
  Prisma12,
  Prisma13,
  Prisma14,
  Prisma15,
  Prisma16,
  Prisma17,
  Prisma18,
  Prisma19,
  Prisma20,
];

const arr2 = [
  Prisma21,
  Prisma22,
  Prisma23,
  Prisma24,
  Prisma25,
  Prisma26,
  Prisma27,
  Prisma28,
  Prisma29,
  Prisma30,
  Prisma31,
  Prisma32,
  Prisma33,
  Prisma34,
  Prisma35,
  Prisma36,
  Prisma37,
  Prisma38,
  Prisma39,
  Prisma40,
];

enum UserType {
  COMMON = 0,
  VIP,
  TEAM,
}

const generateNonce = () => {
  return new Date().getTime();
};

const repeatArray = (arr: Array<any>, repeatTimes: number) =>
  Array.from({ length: repeatTimes }, () => arr).flat();

const domain = window.location.host;
const { origin } = window.location;

const createSiweMessage = (address: string, statement: string) => {
  const now = new Date();
  const oneYearLater = new Date(now.getTime() + 24 * 60 * 60 * 365 * 1000);
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId: CHAIN.idDecimal,
    expirationTime: oneYearLater.toISOString(),
  });
  return message.prepareMessage();
};

// let whitelistMintWrite: any;

export default React.memo(() => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const hasWhitelist: boolean = useSelector(state => state.others.hasWhitelist);
  const stage: StageProps = useSelector(state => state.others.stage);
  const [sig, setSig] = React.useState<string>();
  const [res, setRes] = React.useState<any>();
  const [isWhitelistMintLoading, setIsWhitelistMintLoading] =
    React.useState<any>(false);
  const [isWhitelistMintSuccess, setIsWhitelistMintSuccess] =
    React.useState<any>(false);
  const [isWhitelistMintError, setIsWhitelistMintError] =
    React.useState<any>(false);
  const [isWhitelistSuccessCopy, setIsWhitelistSuccessCopy] =
    React.useState<any>(false);

  // const [res, setRes] = React.useState<any>();
  // const [whitelistMintWrite, setWhitelistMintWrite] = React.useState<any>();
  const [isOnClick, setIsOnClick] = React.useState<any>(false);
  const [nonce, setNonce] = React.useState<number>();
  const [whitelistMintWaitLoading, setWhitelistMintWaitLoading] =
    React.useState<boolean>(false);
  const [whitelistMintData, setWhitelistMintData] = React.useState<any>();

  const [publicMintWaitLoading, setPublicMintWaitLoading] =
    React.useState<boolean>(false);
  const [siweLoading, setSiweLoading] = React.useState<boolean>(false);
  const [haveMinted, setHaveMinted] = React.useState<boolean>(false);

  const { openConnectModal } = useConnectModal();
  const { data: signer } = useSigner();
  const contract = useContract({
    address: PRISMA_CONTRACT_ADDRESS,
    abi: prismaABI.abi,
  });
  const { address, isConnecting, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: balance } = useBalance({
    address,
  });
  const wallet = new Wallet(PRIVATE_KEY);

  const { data: alreadyMintedData } = useContractRead({
    address: PRISMA_CONTRACT_ADDRESS,
    abi: prismaABI.abi,
    functionName: 'alreadyMinted',
    args: [address],
  });

  const fetchSig = async () => {
    const siwe = store.getItem(`siwe:${address}`);
    const _nonce = generateNonce();
    console.log('nonce===>', _nonce);
    setNonce(_nonce);
    const mintReq = {
      to: address!,
      reqType: UserType.VIP,
      nonce: _nonce,
    };
    // const sig = await getSig(mintReq, contract as Prisma721A, wallet);
    const { sig, nonce } = await axios({
      url: `${PRISMA_BACKEND_URL}/whitelistMintSig`,
      method: 'GET',
      params: {
        siwe: uint8ArrayToString(uint8ArrayfromString(siwe), 'base64url'),
      },
    }).then(response => response.data);
    console.log({ sig }, { nonce });
    if (sig === '' && nonce === '') {
      Message({ content: 'Whitelist has expired!', type: MessageTypes.Error });
      throw new Error('Whitelist has expired.');
    }
    setSig(sig);
    setNonce(nonce);
  };

  React.useEffect(() => {
    const siwe = store.getItem(`siwe:${address}`);
    const fetchSig = async () => {
      const _nonce = generateNonce();
      console.log('nonce===>', _nonce);
      setNonce(_nonce);
      const mintReq = {
        to: address!,
        reqType: UserType.VIP,
        nonce: _nonce,
      };
      // const sig = await getSig(mintReq, contract as Prisma721A, wallet);
      const { sig, nonce } = await axios({
        url: `${PRISMA_BACKEND_URL}/whitelistMintSig`,
        method: 'GET',
        params: {
          siwe: uint8ArrayToString(uint8ArrayfromString(siwe), 'base64url'),
        },
      }).then(response => response.data);
      console.log({ sig }, { nonce });
      setSig(sig);
      setNonce(nonce);
    };
    fetchSig().catch(console.error);
  }, []);

  React.useEffect(() => {
    if (address) {
      dispatch(fetchWhetherUserHasWhitelist(address));
    }
  }, [address]);

  React.useEffect(() => {
    dispatch(fetchStage());
  }, []);

  const signInWithEthereum = async () => {
    const statement = `Sign in with Ethereum to the app.`;
    const message = createSiweMessage(await signer!.getAddress(), statement);
    const sig = await signer!.signMessage(message);
    const siweBody = {
      Address: await signer!.getAddress(),
      Sig: sig,
      Msg: message,
    };
    console.log({ siweBody });
    store.setItem(`siwe:${address}`, siweBody);
  };

  const {
    config,
    error,
    isSuccess: isWhitelistSuccess,
  } = usePrepareContractWrite({
    address: PRISMA_CONTRACT_ADDRESS,
    abi: prismaABI.abi,
    functionName: 'mint',
    chainId: CHAIN.idDecimal,
    args: [[address, UserType.VIP, nonce], sig],
  });
  console.log({ config }, { error });

  useEffect(() => {
    if (isWhitelistSuccess) {
      setIsWhitelistSuccessCopy(true);
      setSiweLoading(false);
    }
  }, [isWhitelistSuccess]);
  // useEffect(() => {
  //   setConfig1(config);
  // }, [config]);

  // const {
  //   data: whitelistMintData,
  //   isError: isWhitelistMintError,
  //   isLoading: whitelistMintLoading,
  //   isSuccess: isWhitelistMintSuccess,
  //   write: whitelistMintWrite,
  // } = config && useContractWrite(config);

  const checkChain = () => {
    if (chain?.id !== CHAIN.idDecimal) {
      Message({
        content: `Please switch to ${CHAIN.name} network`,
        type: MessageTypes.Info,
      });
      return false;
    }
    return true;
  };

  const whitelistMint = async () => {
    if (alreadyMintedData === true || haveMinted === true) {
      Message({
        content: 'This address has already minted.',
        type: MessageTypes.Info,
      });
      return;
    }
    const siwe = store.getItem(`siwe:${address}`);
    try {
      setSiweLoading(true);
      if (!siwe) {
        await signInWithEthereum();
        // setSiweLoading(false);
      }
      // setSiweLoading(true);
      try {
        await fetchSig();
      } catch (error) {
        setSiweLoading(false);
      }
      // setSiweLoading(false);
    } catch (error) {
      setSiweLoading(false);
    }
    if (!checkChain()) {
    }
    // res?.whitelistMintWrite?.();
  };

  useEffect(() => {
    // console.log({ whitelistMintWrite });
    if (isOnClick && res?.whitelistMintWrite) {
      res?.whitelistMintWrite?.();
      setIsOnClick(false);
      // setTimeout(() => {
      //   setSiweLoading(false);
      // }, 1000);
    }
  }, [res?.whitelistMintWrite, isOnClick]);

  const { config: publicMintConfig, error: publicMintError } =
    usePrepareContractWrite({
      address: PRISMA_CONTRACT_ADDRESS,
      abi: prismaABI.abi,
      functionName: 'payAndMint',
      chainId: CHAIN.idDecimal,
      overrides: {
        from: address!,
        value: ethers.utils.parseEther('0.005'),
      },
    });

  console.log({ publicMintConfig }, { publicMintError });
  const {
    data: publicMintData,
    write: publicMintWrite,
    isError: isPublicMintError,
    isLoading: publicMintLoading,
    isSuccess: isPublicMintSuccess,
  } = useContractWrite(publicMintConfig);

  const txWait = ({
    setLoading,
    tx,
  }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    tx: SendTransactionResult;
  }) => {
    setLoading(true);
    tx?.wait()
      .then(r => {
        let tokenId: string | number = '';
        r.logs.find(log => {
          if (
            log.topics[0] ===
            '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
          ) {
            tokenId = Number(log.topics[3]);
          }
        });
        Message({
          content: `Mint successfully! <a href="${
            IS_MAINNET
              ? 'https://opensea.io/assets/ethereum/'
              : 'https://testnets.opensea.io/assets/goerli/'
          }${PRISMA_CONTRACT_ADDRESS}/${tokenId}" target="_blank">View on Opensea</a>`,
          duration: 30_000,
        });
        setLoading(false);
        setHaveMinted(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        Message({
          content: `Mint failed`,
          type: MessageTypes.Error,
        });
      });
  };

  useEffect(() => {
    if (isWhitelistMintSuccess) {
      txWait({
        setLoading: setWhitelistMintWaitLoading,
        tx: whitelistMintData,
      });
    }
  }, [isWhitelistMintSuccess]);

  useEffect(() => {
    if (isPublicMintSuccess) {
      txWait({ setLoading: setPublicMintWaitLoading, tx: publicMintData! });
    }
  }, [isPublicMintSuccess]);

  useEffect(() => {
    if (isWhitelistMintError) {
      Message({
        content: `Mint failed`,
        type: MessageTypes.Error,
      });
    }
  }, [isWhitelistMintError]);

  useEffect(() => {
    if (isPublicMintError) {
      Message({
        content: `Mint failed`,
        type: MessageTypes.Error,
      });
    }
  }, [isPublicMintError]);

  const publicMint = async () => {
    if (!checkChain()) {
      return;
    }
    if (Number(balance?.formatted) >= 0.005) {
      publicMintWrite?.();
    } else {
      Message({ content: 'Not enough balance', type: MessageTypes.Info });
    }
  };
  console.log({ whitelistMintWaitLoading });
  console.log({ isWhitelistSuccess });
  console.log({ config });
  console.log(siweLoading, isWhitelistMintLoading, whitelistMintWaitLoading);
  return (
    <div className={styles.home}>
      {/* <button onClick={() => signInWithEthereum()}>Sign</button> */}
      {/* <Follow username="OwnershipLabs" /> */}
      {(isWhitelistSuccess || isWhitelistSuccessCopy) && config && (
        <Index2
          config={config}
          getWrite={(whitelistMintWrite: any) => {
            if (whitelistMintWrite) {
              setRes({ whitelistMintWrite });
              setSiweLoading(false);
            }
          }}
          getRes={(res: any) => {
            console.log({ res44: res });
            // if (res?.whitelistMintData) {
            // setRes(res);
            console.log({
              'res?.whitelistMintLoading': res?.whitelistMintLoading,
            });
            setWhitelistMintData(res?.whitelistMintData);
            setIsWhitelistMintSuccess(res?.isWhitelistMintSuccess);
            setIsWhitelistMintError(res?.isWhitelistMintError);
            setIsWhitelistMintLoading(res?.whitelistMintLoading);
            // }
          }}
        />
      )}
      <Header />
      <div className={styles.container1} style={{ height: `${innerHeight}px` }}>
        {/* <LoopGallery
          imageUrlArray={repeatArray(arr1, 1)}
          direction={Direction.Right}
        /> */}
        <div>
          <img className={styles['prisma-logo']} src={PrismaLogo} />
        </div>
        <div className={styles.supply}>
          {/* <img src={totalSupply} className={styles.number} /> */}
          <img src={dataverseFont} className={styles.dataverse} />
          <div className={styles.text}>Testnet Starts</div>
        </div>
        {isConnected ? (
          <div className={styles['button-container']}>
            {hasWhitelist ? (
              <div>
                {stage.whitelistMint ? (
                  <div
                    className={classNames({
                      [styles.button]: true,
                      [styles.button_disable]: false,
                    })}
                    onClick={() => {
                      setIsOnClick(true);
                      whitelistMint();
                    }}
                  >
                    {siweLoading ||
                    isWhitelistMintLoading ||
                    whitelistMintWaitLoading ? (
                      <img src={loadingBlack} className={styles.loading} />
                    ) : (
                      <span className={styles.text}>Whitelist Mint</span>
                    )}
                  </div>
                ) : (
                  <div
                    className={classNames({
                      [styles.button]: true,
                      [styles.button_disable]: true,
                    })}
                    // onClick={() => {
                    //   setIsOnClick(true);
                    //   whitelistMint();
                    // }}
                  >
                    {siweLoading ||
                    isWhitelistMintLoading ||
                    whitelistMintWaitLoading ? (
                      <img src={loadingBlack} className={styles.loading} />
                    ) : (
                      <span className={styles.text}>Whitelist Mint</span>
                    )}
                  </div>
                )}
                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    marginTop: '5px',
                    maxWidth: '180px',
                  }}
                >
                  {/* Whitelist mint is suspended until UTC 13:00 11.12.2022. */}
                  You have achieved whitelist.
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={classNames({
                    [styles.button]: true,
                    [styles.button_disable]: false,
                  })}
                  onClick={async () => {
                    const siwe = store.getItem(`siwe:${address}`);
                    if (!siwe) {
                      await signInWithEthereum();
                    }
                    history.push('/whitelist');
                  }}
                  // onClick={()=>{
                  //   Message({content: "Whitelist task has not yet started.", type: MessageTypes.Info})
                  // }}
                >
                  {/* <span className={styles.text}>Get Whitelist</span> */}
                  <span className={styles.text}>Curation Task</span>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    marginTop: '5px',
                    maxWidth: '240px',
                    position: 'relative',
                    left: '-30px',
                  }}
                >
                  Try dataverse through curation task. Whitelist reward closed,
                  but you will be recorded as early supporter.
                </div>
              </div>
            )}
            <div>
              <div
                className={classNames({
                  [styles.button]: true,
                  [styles.button_disable]: true,
                })}
                // onClick={publicMint}
                onClick={() => {
                  Message({
                    content: 'Public sale has not yet started.',
                    type: MessageTypes.Info,
                  });
                }}
              >
                {publicMintLoading || publicMintWaitLoading ? (
                  <img src={loadingBlack} className={styles.loading} />
                ) : (
                  <span className={styles.text}>Next Phase</span>
                )}
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                Coming soon...
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.button} onClick={openConnectModal}>
            {isConnecting ? (
              <div>Loading...</div>
            ) : (
              <span className={styles.text}>Connect Wallet</span>
            )}
          </div>
        )}

        {/* <LoopGallery
          imageUrlArray={repeatArray(arr2, 1)}
          direction={Direction.Left}
        /> */}
        <img src={background1} className={styles.background1} />
        <img src={atmosphere1} className={styles.atmosphere1} />
      </div>
      <Intro />
      <BlurDivider />
      <Interests />
      <Timeline />
      <TryDataverse />
      <Footer />
    </div>
  );
});
