import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BindSocialMediaProps {
  twitter: boolean;
  discord: boolean;
}

interface StateProps {
  walletAddressSocialMediaBindingMap: Record<string, BindSocialMediaProps>;
}

const initialState: StateProps = {
  walletAddressSocialMediaBindingMap: {},
};

export const bindSocialMediaSlice = createSlice({
  name: 'bindSocialMedia',
  initialState,
  reducers: {
    setTwitter: (
      state,
      action: PayloadAction<{ address: string; data: boolean }>,
    ) => {
      let data =
        state.walletAddressSocialMediaBindingMap[action.payload.address];
      console.log({ data });
      if (!data) {
        data = { twitter: true, discord: false } as BindSocialMediaProps;
        state.walletAddressSocialMediaBindingMap[action.payload.address] = data;
      } else {
        state.walletAddressSocialMediaBindingMap[
          action.payload.address
        ].twitter = true;
      }
    },
    setDiscord: (
      state,
      action: PayloadAction<{ address: string; data: boolean }>,
    ) => {
      let data =
        state.walletAddressSocialMediaBindingMap[action.payload.address];
      console.log({ data });
      if (!data) {
        data = { twitter: false, discord: true } as BindSocialMediaProps;
        state.walletAddressSocialMediaBindingMap[action.payload.address] = data;
      } else {
        state.walletAddressSocialMediaBindingMap[
          action.payload.address
        ].discord = true;
      }
    },
  },
});

export default bindSocialMediaSlice.reducer;
