import React from 'react';
import styles from './Interests.module.css';
import Interest from './Interest';
import interestsTitle from '@/assets/images/interests-title.png';
import interests1 from '@/assets/images/interests1.png';
import interests2 from '@/assets/images/interests2.png';
import interests3 from '@/assets/images/interests3.png';
import i1 from '@/assets/images/01.png';
import i2 from '@/assets/images/02.png';
import i3 from '@/assets/images/03.png';
import stone from '@/assets/images/stone.png';
import light from '@/assets/images/light.png';
import cloud from '@/assets/images/cloud.png';

export default React.memo(() => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>Own Your Prisma and Get Rewards</div> */}
      <img src={interestsTitle} className={styles.title} />
      <div className={styles.content}>
        <Interest
          img={interests1}
          textImg={i1}
          imgVariant={{
            hover: {
              scale: 1.13,
            },
            initial: {
              scale: 1,
            },
          }}
          effectImg={stone}
          effectStyle={{ width: 282, height: 282, left: -35, top: -70 }}
          effectVariant={{
            hover: {
              opacity: 1,
            },
            initial: {
              opacity: 0,
            },
          }}
        >
          Early Access to
          <br /> Dataverse New Features
        </Interest>
        <Interest
          img={interests2}
          textImg={i2}
          imgVariant={{
            hover: {
              rotate: -80,
            },
            initial: {
              rotate: 0,
            },
          }}
          effectStyle={{ width: 186, height: 186, left: -29, top: -79 }}
          effectImg={light}
          effectVariant={{
            hover: {
              opacity: 1,
            },
            initial: {
              opacity: 0,
            },
          }}
        >
          Qualification for Future
          <br />
          Airdrop
        </Interest>
        <Interest
          img={interests3}
          textImg={i3}
          style={{ width: 130, height: 130, left: -15, top: -50, zIndex: 2 }}
          textImgStyle={{ left: 65, zIndex: 3 }}
          effectImg={cloud}
          effectVariant={{
            hover: {
              opacity: 1,
            },
            initial: {
              opacity: 0,
            },
          }}
          effectStyle={{
            width: 289,
            height: 289,
            left: -48,
            top: -120,
            zIndex: 1,
          }}
        >
          Physical Products
        </Interest>
      </div>
    </div>
  );
});
