import { Switch, Route } from '@modern-js/runtime/router';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ALCHEMY_API_KEY, IS_MAINNET } from './config';
import TwitterCallback from './pages/Callback/TwitterCallback';
import DiscordCallback from './pages/Callback/DiscordCallback';
import HomePage from '@/pages/Home/index';
import WhitelistPage from '@/pages/Whitelist';
import rootStore from '@/state/store';

import './App.css';

const { chains, provider } = configureChains(
  IS_MAINNET ? [chain.mainnet, chain.polygon] : [chain.goerli],
  [
    alchemyProvider({ apiKey: ALCHEMY_API_KEY }),
    alchemyProvider({ apiKey: 'XDtP_lDROH_tI9Z0hNkiXEr6LexJ7qZQ' }),
    publicProvider(),
  ],
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const App = () => (
  <Provider store={rootStore.store}>
    <PersistGate persistor={rootStore.persistor}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <Switch>
            <Route exact={true} path="/" component={HomePage} />
            <Route exact={true} path="/whitelist" component={WhitelistPage} />
            <Route
              exact={true}
              path="/twitter-callback"
              component={TwitterCallback}
            />
            <Route
              exact={true}
              path="/dc-callback"
              component={DiscordCallback}
            />
            <Route path="*">
              <div>404</div>
            </Route>
          </Switch>
        </RainbowKitProvider>
      </WagmiConfig>
    </PersistGate>
  </Provider>
);

export default App;
