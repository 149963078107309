import React from 'react';
import { useLocation } from '@modern-js/runtime/router';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { PRISMA_BACKEND_URL } from '@/config';
import store from '@/utils/storage';
import { useAppDispatch } from '@/state/hook';
import { bindSocialMediaSlice } from '@/state/bindSocialMedia/slice';

export default React.memo(() => {
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     window.close();
  //   }, 2000);
  // }, []);

  const [message, setMessage] = React.useState<string>('Authorising...');
  const { address } = useAccount();
  console.log({ address });
  const dispatch = useAppDispatch();

  const location = useLocation();
  console.log({ location });
  // const code: string = location.search.split('&')[1].slice(5);
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  console.log({ code });
  const siwe = store.getItem(`siwe:${address}`);
  // console.log({ siwe }, JSON.parse(siwe));
  axios({
    url: `${PRISMA_BACKEND_URL}/bindTwitter`,
    method: 'POST',
    data: {
      code,
      siwe: JSON.parse(siwe),
    },
  })
    .then(response => {
      console.log(response);
      if (response.data === 'bind success!') {
        dispatch(
          bindSocialMediaSlice.actions.setTwitter({
            address: address!,
            data: true,
          }),
        );
        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        setMessage('Failed, please try again.');
      }
    })
    .catch(error => {
      console.log(error);
    });

  return <div>{message}</div>;
});
