import React from 'react';
import styles from './TryDataverse.module.css';
import { DATAVERSE } from '@/config';

export default React.memo(() => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>Try Dataverse &</div>
        <div className={styles.title}>Get Whitelisted by Curation Task</div>
        <div className={styles.button}>
          <a
            href={`${DATAVERSE}`}
            target="_blank"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <span className={styles.text}>Enter Dataverse</span>
          </a>
        </div>
      </div>
    </div>
  );
});
