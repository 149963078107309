import React from 'react';
import styles from './InputTweetUrl.module.css';
import { useAppDispatch } from '@/state/hook';
import { othersSlice } from '@/state/others/slice';

export const InputTweetUrl: React.FC = () => {
  const [tweetUrl, setTweetUrl] = React.useState<string>();
  const dispatch = useAppDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTweetUrl(value);
  };

  const submit = () => {
    if (tweetUrl) {
      dispatch(othersSlice.actions.setTweetUrl(tweetUrl));
    }
  };
  const placeholder =
    'example:https://twitter.com/whyyoutouzhele/status/1597392221430423552';
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Copy & Paste your tweet url below to verify
      </div>
      <textarea
        className={styles.input}
        placeholder={placeholder}
        onChange={event => {
          handleChange(event);
        }}
      />
      <div
        className={styles.button}
        onClick={() => {
          submit();
        }}
      >
        <div className={styles.text}>Submit</div>
      </div>
    </div>
  );
};
