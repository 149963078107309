import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PRISMA_BACKEND_URL } from '@/config';
import Message, { MessageTypes } from '@/components/Message/index';

interface TaskProcessProps {
  task: number;
  steps: Array<number>;
  haveFinishedAllTasks: boolean;
}

const initialState: TaskProcessProps = {
  task: 1,
  steps: [1],
  haveFinishedAllTasks: false,
};

export const fetchTaskProcess = createAsyncThunk(
  'taskProcess/fetchTaskProcess',
  async (args: string) => {
    const response = axios({
      url: `${PRISMA_BACKEND_URL}/taskProcess`,
      method: 'GET',
      params: {
        address: args,
      },
    }).then(response => response.data);
    return response;
  },
);

interface Siwe {
  Msg: string;
  Sig: string;
  Address: string;
}

export const requestNextTask = createAsyncThunk(
  'taskProcess/requestNextTask',
  async (args: Siwe) => {
    const response = axios({
      url: `${PRISMA_BACKEND_URL}/next`,
      method: 'POST',
      data: {
        siwe: args,
      },
    }).then(response => response.data);
    return response;
  },
);

export const acquireWhitelist = createAsyncThunk(
  'taskProcess/acquireWhitelist',
  async (args: Siwe) => {
    const response = axios({
      url: `${PRISMA_BACKEND_URL}/acquireWhitelist`,
      method: 'POST',
      data: {
        siwe: args,
      },
    }).then(response => response.data);
    return response;
  },
);

export const taskProcessSlice = createSlice({
  name: 'taskProcess',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTaskProcess.fulfilled, (state, action) => {
      state.task = action.payload.task;
      state.steps = action.payload.step;
    });
    builder.addCase(requestNextTask.fulfilled, (state, action) => {
      if (action.payload.success === true) {
        state.task += 1;
        state.steps = [];
      } else {
        state.steps = action.payload.step;
      }
    });
    builder.addCase(acquireWhitelist.fulfilled, (state, action) => {
      state.haveFinishedAllTasks = action.payload.result;
      const { message } = action.payload;
      if (action.payload.result === false) {
        Message({ content: message, type: MessageTypes.Error });
      }
    });
  },
});

export default taskProcessSlice.reducer;
