import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './index.module.css';

export enum MessageTypes {
  Success = 'Success',
  Info = 'Info',
  Error = 'Error',
}

interface MessageProps {
  content: any;
  type?: MessageTypes;
  i18?: boolean;
  isRichText?: boolean;
}

export const MessageComp = function (props: MessageProps) {
  const type: MessageTypes = props.type || MessageTypes.Success;

  return (
    <div className={styles.messageWrapper}>
      <div
        className={classnames({
          [styles.messageBox]: true,
          [styles.success]: type === MessageTypes.Success,
          [styles.error]: type === MessageTypes.Error,
          [styles.info]: type === MessageTypes.Info,
        })}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </div>
    </div>
  );
};

const message = (props: MessageProps & { duration?: number }) => {
  const holder = document.createElement('div');
  holder.setAttribute('id', 'MessageBox');
  document.body.append(holder);

  const destroy = () => {
    holder.remove();
  };

  if (props.duration !== 0) {
    setTimeout(() => {
      destroy();
    }, props.duration ?? 3000);
  }

  holder.addEventListener('click', () => {
    holder.remove();
  });

  if (props.i18 === undefined) {
    props.i18 = true;
  }

  ReactDOM.render(<MessageComp {...props} />, holder);
};

export default message;
