import React from 'react';
import styles from './Timeline.module.css';
import timelineTitle from '@/assets/images/timeline-title.png';
import timeline1 from '@/assets/images/timeline1.png';
import timeline2 from '@/assets/images/timeline2.png';
import timeline3 from '@/assets/images/timeline3.png';
import timeAxis from '@/assets/images/timeAxis.png';

export default React.memo(() => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>Timeline</div> */}
      <img src={timelineTitle} className={styles.title} />
      {/* <img className={styles.img} src={Timeline} /> */}
      <div className={styles.content}>
        <img className={styles.timeAxis} src={timeAxis} />
        <div className={styles.timeline}>
          <div className={styles.imageBox}>
            <img
              src={timeline3}
              className={styles.image}
              style={{ width: '118px', marginTop: '100px' }}
            />
          </div>
          <span className={styles.text}>Early Supporter Mint</span>
          <span className={styles.time}>2022.12.2</span>
        </div>
        <div
          className={styles.timeline}
          style={{ transform: 'translateX(-20px)' }}
        >
          <div className={styles.imageBox}>
            <img
              src={timeline2}
              className={styles.image}
              style={{ width: '80px', marginTop: '150px' }}
            />
          </div>
          <span className={styles.text}>Phase I Curation Tasks</span>
          <span className={styles.time}>2022.12.8</span>
        </div>
        <div className={styles.timeline}>
          <div className={styles.imageBox}>
            <img
              src={timeline1}
              className={styles.image}
              // style={{ width: '118px', marginTop: '100px' }}
              style={{ width: '392px', marginTop: '20px' }}
            />
          </div>
          <span className={styles.text} style={{ marginLeft: '40px' }}>
            Phase II X Tasks
          </span>
          <span className={styles.time} style={{ marginLeft: '40px' }}>
            to be determined
          </span>
        </div>
      </div>
    </div>
  );
});
