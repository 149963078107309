import { motion, Variants } from 'framer-motion';
import React, { useState } from 'react';
import styles from './Interests.module.css';

const Interest: React.FC<{
  img: string;
  textImg: string;
  effectImg: string;
  effectStyle?: React.CSSProperties;
  imgVariant?: Variants;
  effectVariant?: Variants;
  style?: React.CSSProperties;
  textImgStyle?: React.CSSProperties;
}> = ({
  img,
  textImg,
  imgVariant,
  children,
  style,
  textImgStyle,
  effectImg,
  effectStyle,
  effectVariant,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const backgroundVariants: Variants = {
    hover: {
      background: '#222',
    },
    initial: {
      background: 'rgba(0,0,0,0)',
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const commonTransition = { duration: 0.75, ease: 'easeOut' };
  return (
    <motion.div
      className={styles.interest}
      variants={backgroundVariants}
      animate={isHovered ? 'hover' : 'initial'}
      onPointerEnter={handleMouseEnter}
      onPointerLeave={handleMouseLeave}
      transition={commonTransition}
    >
      <motion.img
        src={img}
        className={styles.image}
        style={style}
        variants={imgVariant}
        transition={commonTransition}
        animate={isHovered ? 'hover' : 'initial'}
      />
      <img src={textImg} className={styles.textImg} style={textImgStyle} />
      <motion.img
        src={effectImg}
        className={styles.textImg}
        style={effectStyle}
        variants={effectVariant}
        transition={commonTransition}
        animate={isHovered ? 'hover' : 'initial'}
      />
      <motion.span
        className={styles.text}
        variants={{
          hover: {
            x: 0,
            y: 0,
          },
          initial: {
            x: -10,
            y: -10,
          },
        }}
        transition={commonTransition}
        animate={isHovered ? 'hover' : 'initial'}
      >
        {children}
      </motion.span>
    </motion.div>
  );
};

export default Interest;
