import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useHistory } from '@modern-js/runtime/router';
import Message, { MessageTypes } from '../Message';
import styles from './Header.module.css';
import logo from '@/assets/images/logo-white.png';
import discord from '@/assets/svg/discord-colorful.svg';
import opensea from '@/assets/svg/opensea-colorful.svg';
import twitter from '@/assets/svg/twitter-logo-blue.svg';
import { DISCORD, OPENSEA, TWITTER_PRISMA_URL } from '@/config';
import { useSelector } from '@/state/hook';

const styledX = <div style={{ color: '#E78C21' }}>x</div>;

export default React.memo(() => {
  const history = useHistory();
  const hasWhitelist = useSelector(state => state.others.hasWhitelist);
  return (
    <div className={styles.header}>
      <div
        className={styles['brand-container']}
        onClick={() => {
          history.push('/');
        }}
      >
        {/* <img src={logo} className={styles.logo} /> */}
        <div className={styles['dataverse-text']}>Prisma</div>
      </div>
      <div style={{ display: 'flex ', alignItems: 'center' }}>
        <div className={styles.linkBox}>
          <img
            src={discord}
            className={styles.link}
            onClick={() => window.open(DISCORD)}
          />
          {/* <img
            src={opensea}
            className={styles.link}
            onClick={() => window.open(OPENSEA)}
          /> */}
          <img
            src={twitter}
            className={styles.link}
            style={{ width: '28px' }}
            onClick={() => window.open(TWITTER_PRISMA_URL)}
          />
        </div>
        <div className={styles.menu}>
          <div
            className={styles['menu-text']}
            onClick={() => {
              history.push('/');
            }}
          >
            Home
          </div>
          {hasWhitelist ? (
            <div
              className={styles['menu-text']}
              onClick={() => {
                Message({
                  content: 'You have already got a whitelist.',
                  type: MessageTypes.Info,
                });
              }}
            >
              Task
            </div>
          ) : (
            <div
              className={styles['menu-text']}
              onClick={() => {
                history.push('/whitelist');
              }}
            >
              Task
            </div>
          )}
          {/* <div
            className={styles['menu-text-disabled']}
            onClick={() => {
              Message({
                content: 'Whitelist task has not yet started.',
                type: MessageTypes.Info,
              });
            }}
          >
            Task
          </div> */}
        </div>
        <div className={styles.connectButton}>
          <ConnectButton showBalance={true} />
        </div>
      </div>
    </div>
  );
});
