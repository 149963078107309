export const devMode = false;
export const IS_MAINNET = true;

export const PRIVATE_KEY =
  '8dd1a784a361c73aa872a976f7b40f553658249a061b343faf94ad22599c84a7';
export const CHAIN = IS_MAINNET
  ? { idDecimal: 1, idHex: '0x1', name: 'Ethereum' }
  : { idDecimal: 5, idHex: '0x5', name: 'Goerli' };
export const PRISMA_CONTRACT_ADDRESS = IS_MAINNET
  ? '0x9fad28fcb80eB63B5001CA568bcE5ED5D4A0102B'
  : '0x1Cc2d63d8FcFb592cecDa987A80e17E275B54134';
export const ALCHEMY_API_KEY = IS_MAINNET
  ? 'fCHSWyYKLLgrEla_z7AXkkVqPdMnmdso'
  : '9w8Ow8qtS319ubmYhYDVQLN4eg38YCxH';
export const DISCORD_AUTH_URL =
  'https://discord.com/oauth2/authorize?response_type=code&client_id=1044209002860515448&state=state&scope=identify&redirect_uri=https://prisma.dataverse-os.com/dc-callback';
export const TWITTER_AUTH_URL =
  'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=MWFyTkV3aWt1T0RoeWxfSF9rRG46MTpjaQ&state=state&scope=tweet.read%20users.read&redirect_uri=https%3A%2F%2Fprisma.dataverse-os.com%2Ftwitter-callback&code_challenge=gsdsdfndsbdSDFfhgdgeriporweuSFGHlrpwoebsdgs450235_fgsdg&code_challenge_method=plain';
export const PRISMA_BACKEND_URL = devMode
  ? 'http://localhost:8181/prisma'
  : 'https://gateway.dataverse.art/prisma';

export const EXTENSION_ID = devMode
  ? 'kcigpjcafekokoclamfendmaapcljead'
  : 'kcigpjcafekokoclamfendmaapcljead';
export const DATAVERSE = 'https://dataverse-os.com';

export const GITCOIN =
  'https://gitcoin.co/grants/3531/dataverse-curate-nfts-from-around-the-web-with-on';
export const TWITTER = 'https://twitter.com/DataverseOS';
export const MEDIUM = 'https://medium.com/@DataverseOS';
export const DISCORD = 'https://discord.gg/GcVMaVMhQU';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCGBbj8--w36ZwTaWakngcXw';
export const GITHUB = 'https://github.com/ownership-labs';
export const OPENSEA = IS_MAINNET
  ? 'https://opensea.io/collection/dataverse-prisma'
  : 'https://testnets.opensea.io/collection/prisma-swqciz2gvr';

export const TWITTER_PRISMA_URL =
  'https://twitter.com/DataverseOS/status/1596051804542099461 ';
