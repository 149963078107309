import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ButtonWrap } from './ButtonWrap';

export interface ButtonType {
  type?: 'primary' | 'ghost' | 'link' | 'text' | 'default';
}
export interface ButtonProps extends ButtonType {
  label: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  width?: number | string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  style,
  width = 80,
  type = 'default',
}) => {
  const { t } = useTranslation();
  return (
    <ButtonWrap width={width} type={type}>
      <motion.div
        whileTap={{ scale: 0.92 }}
        className="buttonContainer"
        onClick={() => {
          onClick?.();
        }}
        style={style}
      >
        {t(label)}
      </motion.div>
    </ButtonWrap>
  );
};

export default Button;
