import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { PRISMA_BACKEND_URL } from '@/config';

export interface StageProps {
  whitelistTask: boolean;
  whitelistMint: boolean;
  publicMint: boolean;
}

interface Props {
  hasWhitelist: boolean;
  stage: StageProps;
  tweetUrl: string;
}

const initialState: Props = {
  hasWhitelist: false,
  stage: {
    whitelistTask: false,
    whitelistMint: false,
    publicMint: false,
  },
  tweetUrl: '',
};

export const fetchStage = createAsyncThunk('others/fetchStage', async () => {
  const response = axios({
    url: `${PRISMA_BACKEND_URL}/timelineStage`,
    method: 'GET',
  }).then(response => response.data);
  return response;
});

export const fetchWhetherUserHasWhitelist = createAsyncThunk(
  'others/fetchWhetherUserHasWhitelist',
  async (address: string) => {
    const response = axios({
      url: `${PRISMA_BACKEND_URL}/hasWhitelist`,
      method: 'GET',
      params: {
        address,
      },
    }).then(response => response.data);
    return response;
  },
);

export const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    setTweetUrl: (state, action: PayloadAction<string>) => {
      state.tweetUrl = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStage.fulfilled, (state, action) => {
      state.stage.whitelistTask = action.payload.whitelistTask;
      state.stage.whitelistMint = action.payload.whitelistMint;
      state.stage.publicMint = action.payload.publicMint;
    });
    builder.addCase(fetchWhetherUserHasWhitelist.fulfilled, (state, action) => {
      state.hasWhitelist = action.payload.result;
    });
  },
});

export default othersSlice.reducer;
