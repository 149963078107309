/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { useContractWrite } from 'wagmi';

export default React.memo(
  ({
    config,
    getWrite,
    getRes,
  }: {
    config: any;
    getWrite: any;
    getRes: any;
  }) => {
    const {
      data: whitelistMintData,
      isError: isWhitelistMintError,
      isLoading: whitelistMintLoading,
      isSuccess: isWhitelistMintSuccess,
      write: whitelistMintWrite,
    } = useContractWrite(config);
    console.log(whitelistMintLoading);
    getRes?.({
      whitelistMintData,
      whitelistMintLoading,
      isWhitelistMintError,
      isWhitelistMintSuccess,
    });
    useEffect(() => {
      if (!isWhitelistMintSuccess) {
        getWrite(whitelistMintWrite);
      }
    }, [isWhitelistMintSuccess]);
    return <></>;
  },
);
