export function detectExtension(extensionId: string): Promise<boolean> {
  const img = new Image();
  img.src = `chrome-extension://${extensionId}/icons/icon-16x16.png`;
  return new Promise(resolve => {
    img.addEventListener('load', () => {
      resolve(true);
    });
    img.addEventListener('error', () => {
      resolve(false);
    });
  });
}
