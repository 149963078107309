import axios from 'axios';
import { toString as uint8ArrayToString } from 'uint8arrays/to-string';
import { fromString as uint8ArrayfromString } from 'uint8arrays/from-string';
import { detectExtension } from './detectExtension';
import { PRISMA_BACKEND_URL } from '@/config';

export function b64EncodeUnicode(str: string) {
  // return btoa(encodeURIComponent(str));
  return uint8ArrayToString(uint8ArrayfromString(str), 'base64url');
}

export const verifyExtensionInstalled = async (extensionId: string) => {
  return await detectExtension(extensionId);
};

export const verifyFollowTwitter = async (
  siwe: string,
  type: string,
): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/followTwitter`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe), type },
  }).then(response => response.data);
  return result.result;
};

export const verifyJoinDiscord = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/joinDiscord`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyCreateTaskFolder = async (
  siwe: string,
): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/dataverseOSFolder`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyCreateCuration = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/curationDAOFolder`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyCollaborators = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/collaborators`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyCurateOnOpensea = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/curateOnOpensea`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyShareFolderToTwitter = async (
  siwe: string,
  folderName: string,
  tweetUrl: string,
): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/shareFolder`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe), folderName, tweetUrl },
  }).then(response => response.data);
  return result.result;
};

export const verifyJoinCollaboration = async (
  siwe: string,
): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/joinCollaborations`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyUnlockCurations = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/unlockCurations`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyNicknameAndAvatar = async (
  siwe: string,
): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/nicknameAndAvatar`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyTwitterBio = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/twitterBio`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const verifyUnlockedTimes = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/unlockedTimes`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};

export const fetchWhitelistMintSig = async (siwe: string): Promise<boolean> => {
  const result = await axios({
    url: `${PRISMA_BACKEND_URL}/whitelistMintSig`,
    method: 'GET',
    params: { siwe: b64EncodeUnicode(siwe) },
  }).then(response => response.data);
  return result.result;
};
