import React from 'react';
import styles from './Footer.module.css';
import gitcoin from '@/assets/svg/Gitcoin.svg';
import twitter from '@/assets/svg/Twitter.svg';
import discord from '@/assets/svg/Discord.svg';
import medium from '@/assets/images/medium.png';
import youtube from '@/assets/images/youtube.png';
// import youtube from '@/assets/svg/Youtube.svg';
import github from '@/assets/svg/Github.svg';
import { GITCOIN, TWITTER, MEDIUM, DISCORD, YOUTUBE, GITHUB } from '@/config';

export default React.memo(() => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>Dataverse - Your Personal CyberSpace</div>
      <div className={styles.right}>
        <img src={gitcoin} onClick={() => window.open(GITCOIN)} />
        <img src={twitter} onClick={() => window.open(TWITTER)} />
        <img src={discord} onClick={() => window.open(DISCORD)} />
        <img
          src={medium}
          onClick={() => window.open(MEDIUM)}
        />
        <img
          src={youtube}
          onClick={() => window.open(YOUTUBE)}
          style={{ height: '23px' }}
        />
        <img
          src={github}
          onClick={() => window.open(GITHUB)}
          style={{ height: '22px' }}
        />
      </div>
    </div>
  );
});
